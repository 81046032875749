import axios from 'axios';
import qs from 'qs';

import { getApiCoreUrl } from 'helpers';

const baseUrl = `${getApiCoreUrl()}/api/v1/Login`;

const login = () => {
  return axios.get(baseUrl);
};

const logOut = async () => {
  return await axios.post(`${baseUrl}/logOut`);
};

const setupAccount = async (user, token) => {
  const config = {
    headers: {
      t: token
    }
  };
  const res = await axios.put(baseUrl, user, config);
  return res.data;
};

const resetPassword = value => {
  const url = `${baseUrl}/reset_password`;
  const obj = { email: value };
  return axios.post(url, obj);
};

const acknowledgePrivacyPolicy = () => {
  return axios.post(`${baseUrl}/privacy_policy`);
};

const generateQRCode = (generateNewCode = false) => {
  const url = `${baseUrl}/mfa_register?${qs.stringify({
    generateNewCode: generateNewCode
  })}`;
  return axios.get(url);
};

const validateCode = value => {
  const url = `${baseUrl}/mfa_register`;
  const obj = { code: value };
  return axios.post(url, obj);
};

const resetAuthentication = id => {
  return axios.post(`${baseUrl}/reset_authentication/${id}`);
};

const getUserPermissionDetails = () => {
  return axios.get(`${baseUrl}/permission_details`);
};

const activateUser = email => {
  const url = `${baseUrl}/user_activation`;
  return axios.post(url, { email });
};

const lockUser = email => {
  const url = `${baseUrl}/lock_user`;
  return axios.put(url, { email });
};

export const loginService = {
  login,
  logOut,
  setupAccount,
  resetPassword,
  acknowledgePrivacyPolicy,
  generateQRCode,
  validateCode,
  resetAuthentication,
  getUserPermissionDetails,
  activateUser,
  lockUser
};
