import React from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { LightTooltip, PrimaryButton } from 'components';

export const FilterButtonWithBadge = ({ activeFilterCount, isFilterClicked, handleClick }) => {
  const theme = useTheme();
  const [t] = useTranslation('common');

  return (
    <Badge
      data-testid='badge'
      badgeContent={activeFilterCount}
      sx={{
        '& .MuiBadge-badge': {
          color: theme.palette.gray.white,
          backgroundColor: theme.palette.primary.main
        }
      }}>
      <LightTooltip title={t('filter')} placement='bottom-start'>
        <PrimaryButton
          icon={'documentFilterIcon'}
          fill={isFilterClicked ? theme.palette.gray.main : theme.palette.primary.main}
          variant={'white'}
          width={'50px'}
          type={'submit'}
          handleClick={handleClick}
          dataCy='filtersButton'
          dataTestId='filtersButton'
        />
      </LightTooltip>
    </Badge>
  );
};

FilterButtonWithBadge.propTypes = {
  activeFilterCount: PropTypes.number,
  isFilterClicked: PropTypes.bool,
  handleClick: PropTypes.func
};
