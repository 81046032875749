import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogActions, Dialog } from '@mui/material';
import PropTypes from 'prop-types';

import { DialogWarning, PrimaryButton } from 'components';

export const DeleteDialog = ({ isOpen, setIsOpen, callback, selectedCount, text, restore }) => {
  const [t] = useTranslation('common');

  const handleConfirm = () => {
    callback();
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth={'md'}
      data-testid='deleteDialog'>
      <DialogWarning
        setIsOpen={setIsOpen}
        text={t(text, { count: selectedCount, context: `${selectedCount}` })}
      />
      <DialogActions sx={{ p: 4, pt: 6, gap: '10px' }}>
        <PrimaryButton
          variant='white'
          handleClick={() => setIsOpen(false)}
          buttonText={t('cancel')}
          dataCy='cancelDeleteButton'
        />
        <PrimaryButton
          dataTestId={restore ? 'confirmRestore' : 'confirmDelete'}
          handleClick={handleConfirm}
          buttonText={t(restore ? 'restore' : 'confirm')}
          dataCy='confirmDeleteButton'
        />
      </DialogActions>
    </Dialog>
  );
};

DeleteDialog.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  callback: PropTypes.func,
  selectedCount: PropTypes.number,
  text: PropTypes.string,
  restore: PropTypes.bool
};
