import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';

import { CustomCheckbox, DeleteDialog, LightTooltip } from 'components';
import { orderByEnum } from 'helpers';

export const CustomTableHeader = ({
  children,
  dataCy,
  handleDelete,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  order,
  orderBy,
  rowCount,
  selectAllDisabled,
  deleteDialogText = 'areYouSureContent',
  deleteDataTestId = 'deleteMultipleFiles'
}) => {
  const theme = useTheme();
  const [t] = useTranslation('common');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  return (
    <>
      <colgroup>
        <col style={{ width: '2%', minWidth: '45px' }} />
        {headLabel.map(headCell => (
          <col key={headCell.id} style={{ width: headCell.width, minWidth: headCell.minWidth }} />
        ))}
      </colgroup>
      <TableHead>
        <TableRow
          sx={{
            width: '100%',
            height: '50px',
            zIndex: 2
          }}>
          {!selectAllDisabled && (
            <TableCell
              padding='checkbox'
              sx={{
                py: 0.85,
                borderBottom: `1px solid ${theme.palette.gray.light}`,
                '&.MuiTableCell-root': {
                  background: numSelected ? theme.palette.gray.lighter : theme.palette.gray.white,
                  pl: 1.25,
                  borderRadius: 0,
                  boxShadow: 'none'
                }
              }}>
              <CustomCheckbox
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                dataCy={dataCy}
                dataTestId='selectAllDocumentsCheckbox'
              />
            </TableCell>
          )}
          {!!numSelected && (
            <>
              <TableCell
                align='left'
                sx={{
                  py: 0.85,
                  background: theme.palette.gray.lighter,
                  '&.MuiTableCell-root': {
                    borderRadius: 0
                  }
                }}>
                <Typography
                  gutterBottom
                  sx={{
                    fontFamily: theme.typography.fontFamilySecondaryMedium,
                    fontSize: theme.typography.pxToRem(14),
                    color: theme.palette.primary.main,
                    whiteSpace: 'nowrap',
                    mb: 0
                  }}>
                  {t('selectAll')}
                </Typography>
              </TableCell>
              <TableCell
                colSpan={headLabel.length - 1}
                align='right'
                sx={{
                  py: 0.85,
                  background: theme.palette.gray.lighter,
                  '&.MuiTableCell-root': {
                    borderRadius: 0,
                    boxShadow: 'none'
                  },
                  '&.MuiTableCell-root:last-of-type': {
                    pr: '15px'
                  }
                }}>
                {children}
                <LightTooltip title={t('delete')}>
                  <IconButton
                    data-testid={deleteDataTestId}
                    disableRipple
                    sx={{
                      width: '2rem',
                      height: '2rem',
                      color: theme.palette.primary.main,
                      backgroundColor: theme.palette.gray.white,
                      '&:hover': { backgroundColor: theme.palette.gray.light }
                    }}
                    onClick={() => setIsDeleteDialogOpen(true)}>
                    <FontAwesomeIcon
                      icon={faTrash}
                      color={theme.palette.primary.main}
                      width='13px'
                      height='20px'
                    />
                  </IconButton>
                </LightTooltip>
              </TableCell>
            </>
          )}
          {!numSelected &&
            headLabel.map(headCell => (
              <TableCell
                key={headCell.id}
                align={headCell.align}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{
                  py: 0.85,
                  borderBottom: `1px solid ${theme.palette.gray.light}`,
                  '&.MuiTableCell-root': {
                    backgroundColor: theme.palette.gray.white,
                    backgroundImage: 'none',
                    px: '15px',
                    borderRadius: 0
                  }
                }}>
                <TableSortLabel
                  sx={{
                    fontFamily: theme.typography.fontFamilyPrimaryMedium,
                    fontSize: theme.typography.pxToRem(14),
                    fontWeight: theme.typography.fontWeightRegular,
                    color: theme.palette.info.main,
                    '&:hover': {
                      color: headCell.disableOrder
                        ? theme.palette.info.main
                        : theme.palette.primary.main
                    },
                    '&.Mui-active': {
                      color: theme.palette.info.main,
                      '&:hover': {
                        color: theme.palette.primary.main
                      }
                    },
                    cursor: headCell.disableOrder ? 'default' : 'pointer',
                    '& .MuiTableSortLabel-icon': {
                      width: '14px',
                      height: '14px'
                    },
                    '&:hover .MuiTableSortLabel-icon': {
                      color: theme.palette.primary.main
                    }
                  }}
                  hideSortIcon
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : orderByEnum.asc}
                  onClick={() =>
                    !headCell.disableOrder && onRequestSort && onRequestSort(headCell.id)
                  }>
                  {t(headCell.id)}
                  {orderBy === headCell.id ? (
                    <Box sx={{ ...visuallyHidden }}>
                      {order === orderByEnum.desc ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
          <DeleteDialog
            isOpen={isDeleteDialogOpen}
            setIsOpen={setIsDeleteDialogOpen}
            callback={handleDelete}
            selectedCount={numSelected}
            text={deleteDialogText}
          />
        </TableRow>
      </TableHead>
    </>
  );
};

CustomTableHeader.propTypes = {
  children: PropTypes.node,
  dataCy: PropTypes.string,
  handleDelete: PropTypes.func,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf([orderByEnum.asc, orderByEnum.desc]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  selectAllDisabled: PropTypes.bool,
  deleteDialogText: PropTypes.string,
  deleteDataTestId: PropTypes.string
};
