import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faBarsFilter
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Menu, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { filterDropdownOptions } from 'helpers';

import { FilterProperty } from './FilterProperty';
import { useStyles } from './FilterStyling.css';

export const FilterDropdown = ({
  selectedFilterOptions,
  setSelectedFilterOptions,
  configuration,
  page
}) => {
  const theme = useTheme();
  const [t] = useTranslation('common');

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedSubFilterValue, setSelectedSubFilterValue] = useState(() => {
    const subFilterState = {};
    configuration.forEach(config => {
      subFilterState[config.name] = config.defaultSubFilterValue
        ? config.defaultSubFilterValue
        : config.name;
    });
    return subFilterState;
  });

  const classes = useStyles(theme);

  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleOpen = useCallback(
    e => {
      setAnchorEl(e.currentTarget);
      const flatFilterCategory = configuration.find(item => item.isFlatFilter);
      setSelectedCategory(flatFilterCategory || null);
    },
    [configuration]
  );

  return (
    <>
      <Box
        onClick={handleOpen}
        className={classes.stackContainer}
        data-cy='filtersDropdown'
        data-testid='filtersDropdown'>
        <FontAwesomeIcon icon={faBarsFilter} width='16px' />
        <Typography
          sx={{
            flex: 1,
            color: theme.palette.primary.main,
            fontSize: theme.typography.pxToRem(16),
            fontFamily: theme.typography.fontFamilyPrimaryRegular
          }}>
          {t('filters')}
        </Typography>
        <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} width='12px' />
      </Box>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: '280px',
            mt: '10px',
            boxShadow: `0px 0px 10px 0px ${'rgba(99, 115, 129, 0.3)'}`
          }
        }}
        MenuListProps={{
          sx: { padding: '0px' }
        }}>
        {selectedCategory ? (
          <FilterProperty
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            selectedFilterOptions={selectedFilterOptions}
            setSelectedFilterOptions={setSelectedFilterOptions}
            subFilterValue={selectedSubFilterValue[selectedCategory.name]}
            setSelectedSubFilterValue={setSelectedSubFilterValue}
            handleClose={handleClose}
            page={page}
            isFlatFilter={selectedCategory?.isFlatFilter}
          />
        ) : (
          configuration.map(item => {
            if (item.permission) {
              return (
                <MenuItem
                  disableRipple
                  data-cy={`${item.name}DropdownItem`}
                  key={item.name}
                  onClick={() => {
                    setSelectedCategory(item);
                  }}
                  className={classes.filterCategories}>
                  <Typography
                    data-testid={`menuItem-${item.name}`}
                    sx={{
                      color: theme.palette.primary.main,
                      fontFamily: theme.typography.fontFamilyPrimaryRegular,
                      fontSize: theme.typography.pxToRem(14)
                    }}>
                    {item.name === filterDropdownOptions.INTELLIGIBILITY
                      ? t('currentScore')
                      : t(item.name)}
                  </Typography>
                  <FontAwesomeIcon icon={faAngleRight} width='8px' />
                </MenuItem>
              );
            }
          })
        )}
      </Menu>
    </>
  );
};

FilterDropdown.propTypes = {
  selectedFilterOptions: PropTypes.object,
  setSelectedFilterOptions: PropTypes.func,
  configuration: PropTypes.array,
  page: PropTypes.number
};
