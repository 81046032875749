import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, DialogActions, Dialog, DialogTitle, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { ChipField, PrimaryButton } from 'components';
import { UploadFileDropzone } from 'components/UploadFileDropzone/UploadFileDropzone';
import { toastMessage } from 'helpers';
import { documentService, versionService } from 'services';

export const UploadNewVersionDialog = ({
  isOpen,
  setIsOpen,
  documentName,
  documentId,
  refetchVersions
}) => {
  const [t] = useTranslation('common');
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [uploadedFile, setUploadedFile] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const { data: hasActiveUsers = true } = useQuery(
    'hasActiveUsers',
    () => documentService.hasActiveUsers(documentId),
    { enabled: isOpen }
  );

  const useStyles = makeStyles({
    closeIcon: {
      width: '20px',
      height: '20px',
      backgroundColor: theme.palette.gray.lighter,
      borderRadius: '50%',
      padding: '0.15rem',
      cursor: 'pointer',
      color: theme.palette.gray.main,
      marginRight: '15px'
    },
    dialogTitle: {
      color: theme.palette.gray.dark,
      fontFamily: theme.typography.fontFamilyPrimaryRegular,
      fontSize: theme.typography.pxToRem(16),
      width: '450px'
    },
    selectFileText: {
      display: 'flex',
      paddingTop: '15px',
      paddingBottom: '10px',
      color: 'black',
      fontSize: theme.typography.pxToRem(14),
      fontFamily: theme.typography.fontFamilyPrimaryRegular
    },
    dropzoneStyle: {
      backgroundColor: theme.palette.gray.lighter,
      border: `1px dashed ${theme.palette.gray.light}`,
      outline: 'none',
      display: 'flex',
      textAlign: 'left',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '20px',
      width: '450px',
      height: '150px',
      flexDirection: 'column'
    },
    warningText: {
      paddingLeft: '2.5rem',
      color: theme.palette.error.main,
      width: '480px',
      textAlign: 'justify',
      fontSize: theme.typography.pxToRem(14),
      fontFamily: theme.typography.fontWeightMedium
    }
  });
  const classes = useStyles();

  const uploadVersion = async () => {
    await versionService.uploadVersion(uploadedFile[0], documentId).then(() => {
      toastMessage(enqueueSnackbar, closeSnackbar, t('successVersionUpload'), 'success');
      setIsOpen(false);
      setUploadedFile();
      if (refetchVersions) {
        refetchVersions();
      }
    });
    setButtonDisabled(true);
  };

  const onDrop = useCallback(
    acceptedFile => {
      setUploadedFile(acceptedFile);
      acceptedFile.length !== 0 && setButtonDisabled(false);
    },
    [uploadedFile]
  );

  const handleRemove = () => {
    setButtonDisabled(true);
    setUploadedFile();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        setUploadedFile();
      }}
      maxWidth={'md'}
      data-testid='uploadNewVersionDialog'>
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        sx={{ p: 2, pb: 1, width: '520px' }}>
        <DialogTitle className={classes.dialogTitle}>
          {t('uploadVersionOf') + `"${documentName}"`}
        </DialogTitle>
        <CloseIcon
          data-testid='closeIcon'
          onClick={() => setIsOpen(false)}
          className={classes.closeIcon}
        />
      </Grid>
      {hasActiveUsers && (
        <Typography data-testid='activeUsersWarning' className={classes.warningText}>
          {t('uploadVersionWarning')}
        </Typography>
      )}
      <Box
        paddingX='2.5rem'
        style={hasActiveUsers ? { pointerEvents: 'none', opacity: '0.5' } : null}>
        <Typography gutterBottom className={classes.selectFileText}>
          {t('selectFile')}
        </Typography>
        <UploadFileDropzone
          onDrop={onDrop}
          files={uploadedFile}
          warning={t('uploadOneFile')}
          isSingleFileUpload
        />
      </Box>
      {uploadedFile && (
        <div style={{ paddingLeft: '40px', paddingRight: '40px' }}>
          <ChipField
            label={uploadedFile[0].name}
            size={Math.round((uploadedFile[0].size / 1048576) * 100) / 100 + ' MB'}
            handleRemove={() => handleRemove()}
            border={'1px solid'}
            height={50}
            width={'450px'}
            fontFamily={theme.typography.fontFamilyPrimaryMedium}
          />
        </div>
      )}
      <DialogActions sx={{ p: 5, pt: 6 }}>
        <PrimaryButton
          isDisabled={buttonDisabled}
          handleClick={uploadVersion}
          buttonText={t('replace')}
        />
      </DialogActions>
    </Dialog>
  );
};

UploadNewVersionDialog.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  documentName: PropTypes.string,
  documentId: PropTypes.string,
  refetchVersions: PropTypes.func
};
