import axios from 'axios';

import { collectIds, getApiCoreUrl } from 'helpers';

const baseUrl = `${getApiCoreUrl()}/api/v1/Exclusions`;

const getCustomExclusions = (filterData, search, orderBy) => {
  const url = `${baseUrl}/custom`;
  const exclusionOptionsIds = collectIds(filterData?.exclusions?.exclusions);
  const filters = [
    { property: 'search', value: search },
    { property: 'orderBy', value: orderBy },
    { property: 'exclusionOptions', value: exclusionOptionsIds }
  ];

  return axios.post(url, filters).then(res => {
    return res.data;
  });
};

const deleteExclusions = data => {
  const ids = data.map(d => `ids=${d}`);
  const url = `${baseUrl}/custom?${ids.join('&')}`;
  return axios.delete(url);
};

export const exclusionService = {
  getCustomExclusions,
  deleteExclusions
};
