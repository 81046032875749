import React from 'react';
import { useTranslation } from 'react-i18next';

import { Paper, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { Icons } from 'components';

export const SearchNotFound = ({ searchQuery = '' }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();

  return (
    <Paper sx={{ height: '100%' }} align='center' elevation={0} data-testid='searchNotFound'>
      <Box sx={{ paddingTop: '10%', paddingBottom: '3%', height: '100%' }}>
        <Icons iconName={'noResultIcon'} />
      </Box>
      <Typography
        gutterBottom
        align='center'
        variant='subtitle1'
        sx={{
          fontFamily: theme.typography.fontFamilySecondaryBold,
          fontSize: '20px',
          paddingBottom: '2%'
        }}>
        {t('noResultsFound')}
      </Typography>
      <Typography
        variant='body2'
        align='center'
        sx={{
          fontFamily: theme.typography.fontFamilyPrimaryRegular,
          fontSize: '15px'
        }}>
        {t('noResultsFoundFor')}
        <strong>{searchQuery}</strong>.
      </Typography>
      <Typography
        variant='body2'
        align='center'
        sx={{
          fontFamily: theme.typography.fontFamilyPrimaryRegular,
          fontSize: '15px'
        }}>
        {t('tryCheckingForTyposOrUsingCompleteWords')}
      </Typography>
    </Paper>
  );
};

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string
};
