import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { Alert, Box, Link, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  browserSessionPersistence,
  indexedDBLocalPersistence,
  setPersistence,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { Icons, PrimaryButton, TextFieldStyle } from 'components';
import { auth, handleFirebaseErrors, handleShowPassword } from 'helpers';
import { UserContext } from 'providers';
import { loginService } from 'services';

import { useStyles } from './AuthFormsStyle.css';
import { AuthTitle } from './AuthTitle';
import { AUTH_PATHS } from '../../routes/paths';

const NUMBER_OF_LOGIN_ATTEMPTS = 'loginAttempts';
const WRONG_PASSWORD = 'auth/wrong-password';

export const LoginForm = () => {
  const [t] = useTranslation('common');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const theme = useTheme();
  const classes = useStyles(theme);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user, comingFromMFALoginPage, setComingFromMFALoginPage, signOutAsync } =
    useContext(UserContext);

  const loginSchema = Yup.object().shape({
    password: Yup.string().min(8, t('tooShort')).max(50, t('tooLong')).required(t('required')),
    email: Yup.string().email(t('invalidEmail')).required(t('required'))
  });

  const wrongPasswordCounter = async (email, setErrors) => {
    let storedValue = Number(localStorage.getItem(NUMBER_OF_LOGIN_ATTEMPTS));
    if (storedValue === 2) {
      await loginService.lockUser(email).then(res => {
        if (res.status === 200) {
          setErrors({ afterSubmit: t('userAccountDisabledByWrongPassword') });
          localStorage.setItem(NUMBER_OF_LOGIN_ATTEMPTS, 0);
        }
      });
      return;
    }
    localStorage.setItem(NUMBER_OF_LOGIN_ATTEMPTS, ++storedValue);
  };

  const handleLogout = async () => {
    try {
      await signOutAsync();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      enqueueSnackbar(t('unableToLogout'), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (user && comingFromMFALoginPage) {
      handleLogout();
    }
    if (user && !comingFromMFALoginPage) {
      if (user.mfaCode) {
        navigate(AUTH_PATHS.MFA_LOGIN);
      } else {
        navigate(AUTH_PATHS.MFA_REGISTER);
      }
    }
    setComingFromMFALoginPage(false);
  }, [user]);

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      await setPersistence(
        auth,
        rememberMe ? indexedDBLocalPersistence : browserSessionPersistence
      );
      await signInWithEmailAndPassword(auth, values.email, values.password);
      localStorage.setItem(NUMBER_OF_LOGIN_ATTEMPTS, 0);
    } catch (error) {
      error.code === WRONG_PASSWORD && wrongPasswordCounter(values.email, setErrors);
      const message = handleFirebaseErrors(error);
      setErrors({ afterSubmit: t(message) });
    }
    setSubmitting(false);
  };

  return (
    <div>
      <Stack direction='row' alignItems='center' sx={{ mb: 5 }}>
        <Box sx={{ flexGrow: 1, display: 'initial', textAlign: 'center' }}>
          <AuthTitle page='login' />
        </Box>
      </Stack>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={loginSchema}
        onSubmit={handleSubmit}>
        {({ isSubmitting, errors, touched, getFieldProps }) => (
          <Form autoComplete='off' noValidate>
            <Stack spacing={3} sx={{ alignItems: 'center' }}>
              {errors.afterSubmit && <Alert severity='error'>{errors.afterSubmit}</Alert>}
              <TextFieldStyle
                className={classes.textFieldStyle}
                autoComplete='username'
                type='email'
                inputname={t('emailAddress')}
                InputProps={{
                  startAdornment: (
                    <div
                      style={{
                        marginRight: '20px',
                        borderRadius: 'initial',
                        border: 'transparent',
                        marginTop: '5px'
                      }}>
                      <Icons iconName={'envelopeIcon'} />
                    </div>
                  )
                }}
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                placeholder={t('email')}
                helperText={touched.email && errors.email}
                data-testid='email'
                dataCy='emailInput'
              />

              <TextFieldStyle
                className={classes.textFieldStyle}
                autoComplete='current-password'
                type={showPassword ? 'text' : 'password'}
                inputname={t('password')}
                InputProps={{
                  startAdornment: (
                    <div
                      style={{
                        marginRight: '20px',
                        borderRadius: 'initial',
                        border: 'transparent',
                        marginTop: '5px'
                      }}>
                      <Icons iconName={'padlockIcon'} />
                    </div>
                  ),
                  endAdornment: handleShowPassword(showPassword, setShowPassword)
                }}
                placeholder={t('password')}
                {...getFieldProps('password')}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                data-testid='password'
                dataCy='passwordInput'
              />

              <Stack
                direction='row'
                sx={{
                  my: 2,
                  width: '340px',
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}>
                <Stack
                  direction='row'
                  sx={{ alignItems: 'center', cursor: 'pointer' }}
                  onClick={() => setRememberMe(!rememberMe)}
                  data-testid='rememberMeButton'>
                  <Icons
                    iconName={rememberMe ? 'checkboxCheckedIcon' : 'checkboxUncheckedIcon'}
                    width='22px'
                    height='22px'
                    fill={!rememberMe && theme.palette.gray[50]}
                    strokeColor={!rememberMe && theme.palette.gray[400]}
                  />
                  <Typography
                    sx={{
                      fontFamily: theme.typography.fontFamilyPrimaryRegular,
                      color: theme.palette.gray.main,
                      ml: '10px',
                      fontSize: theme.typography.pxToRem(14),
                      mr: '40px',
                      userSelect: 'none'
                    }}>
                    {t('rememberMe')}
                  </Typography>
                </Stack>
                <Stack>
                  <Link
                    component={RouterLink}
                    to={AUTH_PATHS.FORGOT_PASSWORD}
                    sx={{
                      fontFamily: theme.typography.fontFamilyPrimaryMedium,
                      color: theme.palette.quaternary.main,
                      fontSize: theme.typography.pxToRem(14),
                      textDecoration: 'none',
                      '&:hover': { textDecoration: 'underline' },
                      userSelect: 'none'
                    }}>
                    {t('forgotPasswordLink')}
                  </Link>
                </Stack>
              </Stack>

              <PrimaryButton
                type={'submit'}
                loading={isSubmitting}
                buttonText={'login'}
                width={'340px'}
                dataCy='loginButton'
                dataTestId='loginButton'
              />
            </Stack>
          </Form>
        )}
      </Formik>
    </div>
  );
};
