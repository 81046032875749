import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircleIcon from '@mui/icons-material/Circle';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { CustomCheckbox, Scrollbar, SearchWrapper } from 'components';
import {
  checkPermission,
  contentTypes,
  editsStatus,
  exclusionOption,
  filterDropdownOptions,
  filterSearchedProperties,
  filterOptions,
  handleSearch,
  intelligibilityLevelDictionary,
  permissions,
  sourcePage
} from 'helpers';
import { UserContext } from 'providers';
import { userService, teamService } from 'services';

import { FilterItemTooltipWrapper } from './FilterItemTooltipWrapper';
import { useStyles } from './FilterStyling.css';

export const FilterProperty = ({
  selectedCategory,
  setSelectedCategory,
  selectedFilterOptions,
  setSelectedFilterOptions,
  subFilterValue,
  setSelectedSubFilterValue,
  handleClose,
  page,
  isFlatFilter = false
}) => {
  const [t] = useTranslation('common');
  const theme = useTheme();
  const classes = useStyles(theme);
  const { labels, user } = useContext(UserContext);

  const isUsersCategorySelected = selectedCategory.name === filterDropdownOptions.USERS;
  const isExclusionsFilterSelected = selectedCategory.name === filterDropdownOptions.EXCLUSIONS;
  const isIntelligibilityCategorySelected =
    selectedCategory.name === filterDropdownOptions.INTELLIGIBILITY;
  const showSubFilterSelection =
    !isIntelligibilityCategorySelected &&
    selectedCategory.name !== filterDropdownOptions.TYPES &&
    selectedCategory.name !== filterDropdownOptions.EDITS &&
    !isExclusionsFilterSelected;
  const selectedFiltersBySubFilter =
    selectedFilterOptions[selectedCategory.name]?.[subFilterValue] || [];

  const userHasReadPermission = checkPermission(user.permissions, permissions.USERS_READ);

  const queryKey = page === sourcePage.DOCUMENTS ? 'getDocumentFilterUsers' : 'getAccessibleUsers';

  const { data: usersData } = useQuery(queryKey, userService[queryKey], {
    enabled: isUsersCategorySelected && (page !== sourcePage.DOCUMENTS || userHasReadPermission)
  });

  const { data: teamsData } = useQuery('teams', () => teamService.getTeams(), {
    enabled: selectedCategory.name === filterDropdownOptions.TEAMS
  });

  const getItems = () => {
    switch (selectedCategory.name) {
      case filterDropdownOptions.USERS:
        return usersData?.map(item => {
          return { ...item, name: item.displayName, id: item.masterUserIds };
        });
      case filterDropdownOptions.TEAMS:
        return teamsData;
      case filterDropdownOptions.LABELS:
        return labels;
      case filterDropdownOptions.INTELLIGIBILITY:
        return Object.entries(intelligibilityLevelDictionary).map(([key, value]) => ({
          key: key,
          id: value,
          name: t(key)
        }));
      case filterDropdownOptions.EDITS:
        return [
          { id: editsStatus.EDITED, name: t('edited') },
          { id: editsStatus.UNEDITED, name: t('unedited') }
        ];
      case filterDropdownOptions.TYPES:
        return Object.values(contentTypes)
          .map(value => ({
            id: value,
            name: t(`contentType_${value}`)
          }))
          .sort((x, y) => x.name.localeCompare(y.name));
      case filterDropdownOptions.EXCLUSIONS:
        return [
          {
            id: exclusionOption.EXCLUDED_FROM_SCORING_AND_SUGGESTIONS,
            name: t('excludedFromScoring')
          },
          { id: exclusionOption.EXCLUDED_FROM_SUGGESTIONS, name: t('excludedFromSuggestions') }
        ];
    }
  };

  const [dropdownItems, setDropdownItems] = useState(getItems());
  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const searchStyles = useRef({
    width: '260px',
    height: '40px',
    borderRadius: '8px',
    fontFamily: theme.typography.fontFamilyPrimaryRegular,
    fontSize: theme.typography.pxToRem(14),
    border: `1px solid ${theme.palette.gray.light}`,
    marginLeft: '10px',
    marginBottom: '10px'
  });

  const isEqualOrNotEqualSelected = [filterOptions.EQUALS, filterOptions.DOES_NOT_EQUAL].includes(
    subFilterValue
  );

  const isContainsOrNotContainSelected = [
    filterOptions.CONTAINS,
    filterOptions.DOES_NOT_CONTAIN
  ].includes(subFilterValue);

  useLayoutEffect(() => {
    setDropdownItems(filterSearchedProperties(getItems(), search));
  }, [labels, teamsData, usersData, search, subFilterValue]);

  const handleItemSelection = item => {
    let updatedList = [...selectedFiltersBySubFilter];
    const itemIndex = selectionOfUsersHandler(updatedList, item);

    if (itemIndex === -1) {
      updatedList = isContainsOrNotContainSelected ? [item] : [...selectedFiltersBySubFilter, item];
    } else {
      updatedList.splice(itemIndex, 1);
    }

    const updatedSelectedFilterOptions = JSON.parse(JSON.stringify(selectedFilterOptions));
    updatedSelectedFilterOptions[selectedCategory.name][subFilterValue] = updatedList;
    setSelectedFilterOptions(updatedSelectedFilterOptions);
    isContainsOrNotContainSelected && handleClose();
  };

  const onEnterPressed = e => {
    const code = e.keyCode || e.which;
    const updatedSelectedFilterOptions = JSON.parse(JSON.stringify(selectedFilterOptions));
    if (code === 13 && search !== '') {
      const newValue = isEqualOrNotEqualSelected ? dropdownItems : [{ name: search }];
      updatedSelectedFilterOptions[selectedCategory.name][subFilterValue] = newValue;
      setSelectedFilterOptions(updatedSelectedFilterOptions);
      isContainsOrNotContainSelected && handleClose();
    }
  };

  const handleChange = e => {
    setSelectedSubFilterValue(prevState => ({
      ...prevState,
      [selectedCategory.name]: e.target.value
    }));
  };

  const selectionOfUsersHandler = (selectedFiltersList, item) => {
    return selectedFiltersList?.findIndex(i => {
      if (isUsersCategorySelected) {
        return i.id.every((val, index) => val === item.id[index]);
      } else {
        return i.id === item.id;
      }
    });
  };

  const formatIntelligibilityLevel = word => {
    const number = word.match(/\d/)[0];
    return word.replace(`level_${number}`, `Level${number}`);
  };

  return (
    <>
      {!isFlatFilter && (
        <Box
          data-cy='backToAllFilterCategories'
          className={classes.filterProperty}
          onClick={e => {
            e.stopPropagation();
            setSelectedCategory(null);
          }}>
          <FontAwesomeIcon icon={faAngleLeft} width='8px' data-testid='closeIcon' />
          <Typography
            data-testid='selectedCategoryName'
            sx={{
              lineHeight: 1,
              marginLeft: '10px',
              fontSize: theme.typography.pxToRem(14),
              fontFamily: theme.typography.fontFamilyPrimaryRegular
            }}>
            {isIntelligibilityCategorySelected ? t('currentScore') : t(selectedCategory.name)}
          </Typography>
        </Box>
      )}
      {showSubFilterSelection && (
        <Select
          data-testid='selectField'
          data-cy={`${selectedCategory.name}${'SubfilterSelect'}`}
          value={subFilterValue}
          className={classes.dropdownStyle}
          MenuProps={{
            PaperProps: {
              style: {
                marginTop: '5px',
                border: `1px solid ${theme.palette.gray.light}`,
                boxShadow: 'none'
              }
            }
          }}
          onChange={handleChange}>
          {selectedCategory.subFilterValues.map(item => (
            <MenuItem
              disableRipple
              data-cy={`${selectedCategory.name}_${item}Option`}
              key={item}
              onClick={e => e.stopPropagation()}
              value={item}
              className={classes.filterOptions}>
              {t(item)}
            </MenuItem>
          ))}
        </Select>
      )}
      {selectedCategory.isSearchFieldEnabled && (
        <SearchWrapper
          dataCy='labelsFilterSearch'
          search={search}
          searchValue={searchValue}
          setSearch={setSearch}
          setSearchValue={setSearchValue}
          handleSearch={e => {
            handleSearch(e, setSearch);
            e.stopPropagation();
          }}
          onKeyDownHandler={onEnterPressed}
          component='customOutlinedInput'
          style={searchStyles.current}
          placeholder='Search'
        />
      )}
      <Scrollbar sx={{ maxHeight: '245px', minHeight: '50px' }}>
        {dropdownItems?.map(item => (
          <MenuItem
            disableRipple
            data-cy={`${selectedCategory.name}_${subFilterValue}_${item.id}`}
            key={item.id}
            onClick={e => {
              e.stopPropagation();
              handleItemSelection(item);
            }}
            className={classes.menuItems}>
            <Box display='flex' alignItems='center' gap='8px' width='200px'>
              {isIntelligibilityCategorySelected && (
                <CircleIcon
                  sx={{
                    width: 8,
                    height: 8,
                    color: theme.palette[formatIntelligibilityLevel(item.key)].dark
                  }}
                />
              )}
              <FilterItemTooltipWrapper
                hideTooltip={isFlatFilter}
                title={item.name}
                disablePortal={dropdownItems.length > 1}>
                <Typography
                  overflow='hidden'
                  textOverflow='ellipsis'
                  fontFamily={theme.typography.fontFamilyPrimaryRegular}
                  fontSize={theme.typography.pxToRem(14)}
                  data-testid={`menuItem-${item.id}`}>
                  {item.name}
                </Typography>
              </FilterItemTooltipWrapper>
            </Box>
            {!isContainsOrNotContainSelected && (
              <CustomCheckbox
                disableRipple
                checked={selectionOfUsersHandler(selectedFiltersBySubFilter, item) >= 0}
              />
            )}
          </MenuItem>
        ))}
      </Scrollbar>
    </>
  );
};

FilterProperty.propTypes = {
  selectedCategory: PropTypes.object,
  setSelectedCategory: PropTypes.func,
  selectedFilterOptions: PropTypes.object,
  setSelectedFilterOptions: PropTypes.func,
  subFilterValue: PropTypes.string,
  setSelectedSubFilterValue: PropTypes.func,
  handleClose: PropTypes.func,
  page: PropTypes.number,
  isFlatFilter: PropTypes.bool
};
