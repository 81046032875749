import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { addDays, format, sub, subDays, subMonths } from 'date-fns';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';

import { LightTooltip, PrimaryButton } from 'components';
import { dateRangeValues, dateUnit, getStringWithoutSpaces, sourcePage } from 'helpers';
import { filterSetService } from 'services';

import { DatePickerDropdown } from './DatePickerDropdown';
import { FilterDropdown } from './FilterDropdown';
import { SaveFilterSet } from './SaveFilterSet';
import { SelectedFiltersDisplay } from './SelectedFiltersDisplay';

export const FilterContainer = ({
  selectedFilterOptions,
  setSelectedFilterOptions,
  selectedDateFilter = {},
  setSelectedDateFilter,
  handleApplyFilters,
  resetAllFilters,
  configuration,
  dateRangeFilters = [],
  textFieldValue,
  setTextFieldValue,
  page,
  setSelectedFilterSetId,
  groupedFilters,
  setGroupedFilters,
  updatedSelectedFilterOptions,
  shouldFilterByDate = true
}) => {
  const theme = useTheme();

  const isAtLeastOneArrayNotEmpty = Object.values(
    updatedSelectedFilterOptions || selectedFilterOptions
  ).some(option => {
    return Object.values(option).some(arr => arr.length > 0);
  });
  const [t] = useTranslation('common');
  const [selectedCustomDateRange, setSelectedCustomDateRange] = useState([]);
  const [selectedFilterSet, setSelectedFilterSet] = useState(0);
  const [dateRange, setDateRange] = useState([null, null]);
  const fromToText = `${capitalize(t('from'))} - ${capitalize(t('to'))}`;
  const filterSetData = {
    dateFilter:
      selectedDateFilter.id === dateRangeValues.CUSTOM
        ? selectedDateFilter
        : { date: null, id: selectedDateFilter.id },
    filterOptions: selectedFilterOptions
  };

  const { data: filterSets, refetch } = useQuery(['getFilterSets', page], () => {
    return filterSetService.getFilterSets(page);
  });

  const useStyles = makeStyles({
    boxContainer: {
      height: 'auto',
      border: `1px solid ${theme.palette.gray.light}`,
      borderRadius: '0px 0px 16px 16px',
      display: 'flex',
      padding: '14px',
      paddingBottom: isAtLeastOneArrayNotEmpty ? '0px' : '15px',
      justifyContent: 'space-between'
    },
    dateRangeFilterContainer: {
      backgroundColor: theme.palette.gray.lightest,
      border: `1px solid ${theme.palette.gray.light}`,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px'
    }
  });
  const classes = useStyles();

  useEffect(() => {
    if (shouldFilterByDate) {
      selectTimeFrame(selectedDateFilter);
    }
  }, [selectedCustomDateRange]);

  const selectTimeFrame = item => {
    const tomorrow = addDays(new Date(), 1);
    if (item.unit !== dateUnit.CUSTOM) {
      setTextFieldValue(fromToText);
    }
    let pastDate = null;
    if (item.id === dateRangeValues.ALL_TIME) {
      setSelectedDateFilter({ date: null, id: item.id });
    } else if (item.unit === dateUnit.DAY) {
      pastDate = format(sub(tomorrow, { days: item.period }), 'yyyy-MM-dd');
    } else if (item.unit === dateUnit.MONTH) {
      pastDate = format(subMonths(tomorrow, item.period), 'yyyy-MM-dd');
    }
    if (item.unit === dateUnit.CUSTOM && selectedCustomDateRange[0]) {
      setSelectedDateFilter({
        date: [
          selectedCustomDateRange[0]
            ? format(selectedCustomDateRange[0], 'yyyy-MM-dd')
            : selectedCustomDateRange[0],
          selectedCustomDateRange[1]
            ? format(selectedCustomDateRange[1], 'yyyy-MM-dd')
            : selectedCustomDateRange[1]
        ],
        id: item.id
      });
    } else if (item.unit === dateUnit.DAY && item.period === 1) {
      setSelectedDateFilter({
        date: [subDays(new Date(), 2), subDays(new Date(), 1)],
        id: item.id
      });
    } else {
      setSelectedDateFilter({ date: pastDate, id: item.id });
    }
  };

  const renderDateRangeFilter = () => {
    return (
      <div style={{ display: 'flex', padding: '0px 15px' }}>
        {dateRangeFilters.map(item => (
          <div key={item.id}>
            {item.id === dateRangeValues.CUSTOM ? (
              <DatePickerDropdown
                setSelectedCustomDateRange={setSelectedCustomDateRange}
                handleClick={() => selectTimeFrame(item)}
                isOpen={selectedDateFilter.id === dateRangeValues.CUSTOM}
                textFieldValue={textFieldValue}
                setTextFieldValue={setTextFieldValue}
                dateRange={dateRange}
                setDateRange={setDateRange}
                shouldExcludeDates={page !== sourcePage.DOCUMENTS}
              />
            ) : (
              <PrimaryButton
                handleClick={() => selectTimeFrame(item)}
                buttonText={item.filter}
                variant={selectedDateFilter.id === item.id ? 'dark' : 'transparent'}
                width={'fit-content'}
                height={'30px'}
                fontSize={14}
                fontFamily={theme.typography.fontFamilyPrimaryRegular}
                dataCy={`${getStringWithoutSpaces(item.filter)}FilterButton`}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  const handleChipDelete = (category, filterOption) => {
    const updatedSelectFilterOptions = { ...selectedFilterOptions };

    if (updatedSelectFilterOptions[category]) {
      updatedSelectFilterOptions[category][filterOption] = [];
    }
    setSelectedFilterOptions(updatedSelectFilterOptions);

    const updatedGroupedFilters = { ...groupedFilters };

    if (updatedGroupedFilters[category]) {
      updatedGroupedFilters[category][filterOption] = [];
    }
    setGroupedFilters(updatedGroupedFilters);
  };

  const isAllFiltersRestricted = filterConfiguration =>
    filterConfiguration.length === filterConfiguration.filter(item => !item.permission).length;

  const areFiltersOnDefaultValues = selectedDateFilter.date === null && !isAtLeastOneArrayNotEmpty;

  const applyFilterSet = filterSetId => {
    const filterSet = filterSets.find(fs => fs.id === filterSetId);
    const filterState = JSON.parse(filterSet.filterState);
    setSelectedFilterOptions(filterState.filterOptions);
    if (shouldFilterByDate) {
      setSelectedDateFilter(filterState.dateFilter);
      if (filterState.dateFilter.id === dateRangeValues.CUSTOM) {
        const dates = filterState.dateFilter.date;
        setDateRange([new Date(dates[0]), new Date(dates[1])]);
      }
    }
  };

  return (
    <Stack className={classes.boxContainer} data-testid='filterContainer'>
      <Stack sx={{ display: 'flex', justifyContent: 'space-between' }} direction='row'>
        <Stack direction='row' sx={{ height: '50px', gap: '20px' }}>
          {shouldFilterByDate && (
            <Stack className={classes.dateRangeFilterContainer}>{renderDateRangeFilter()}</Stack>
          )}
          {!isAllFiltersRestricted(configuration) && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <FilterDropdown
                selectedFilterOptions={selectedFilterOptions}
                setSelectedFilterOptions={setSelectedFilterOptions}
                configuration={configuration}
                page={page}
              />
              <SaveFilterSet
                isDisabled={areFiltersOnDefaultValues}
                filterSetData={filterSetData}
                page={page}
                filterSets={filterSets}
                refetchFilterSets={refetch}
                selectedFilterSet={selectedFilterSet}
                setSelectedFilterSet={setSelectedFilterSet}
                applyFilterSet={applyFilterSet}
                resetAllFilters={resetAllFilters}
                setSelectedFilterSetId={setSelectedFilterSetId}
              />
            </div>
          )}
        </Stack>
        <Stack direction='row' justifyContent='flex-end' spacing={0.5}>
          <PrimaryButton
            handleClick={handleApplyFilters}
            buttonText={'applyFilters'}
            variant={'white'}
            width={'100px'}
            dataCy='applyFiltersButton'
          />
          <LightTooltip title={t('resetFilters')} placement='bottom-start'>
            <PrimaryButton
              handleClick={() => {
                if (shouldFilterByDate) {
                  setTextFieldValue(fromToText);
                }
                resetAllFilters();
                setSelectedFilterSet(0);
              }}
              variant={'white'}
              width={'50px'}
              icon={'resetFiltersIcon'}
              dataTestId='resetFiltersButton'
              dataCy='resetFiltersButton'
              showBorder={false}
            />
          </LightTooltip>
        </Stack>
      </Stack>
      <SelectedFiltersDisplay
        dataTestId='filterChip'
        isAtLeastOneArrayNotEmpty={isAtLeastOneArrayNotEmpty}
        selectedFilterOptions={updatedSelectedFilterOptions || selectedFilterOptions}
        handleDelete={handleChipDelete}
      />
    </Stack>
  );
};

FilterContainer.propTypes = {
  selectedFilterOptions: PropTypes.object,
  setSelectedFilterOptions: PropTypes.func,
  selectedDateFilter: PropTypes.object,
  setSelectedDateFilter: PropTypes.func,
  handleApplyFilters: PropTypes.func,
  resetAllFilters: PropTypes.func,
  configuration: PropTypes.array,
  dateRangeFilters: PropTypes.array,
  textFieldValue: PropTypes.string,
  setTextFieldValue: PropTypes.func,
  page: PropTypes.number,
  setSelectedFilterSetId: PropTypes.func,
  groupedFilters: PropTypes.object,
  setGroupedFilters: PropTypes.func,
  updatedSelectedFilterOptions: PropTypes.object,
  shouldFilterByDate: PropTypes.bool
};
