import React, { useRef } from 'react';

import PropTypes from 'prop-types';

import { LightTooltip } from 'components';

export const FilterItemTooltipWrapper = ({ children, title, hideTooltip, disablePortal }) => {
  const tooltipStyles = useRef({
    maxWidth: '250px',
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word'
  });

  return hideTooltip ? (
    children
  ) : (
    <LightTooltip
      title={title}
      PopperProps={{
        disablePortal: disablePortal,
        style: tooltipStyles.current
      }}>
      {children}
    </LightTooltip>
  );
};

FilterItemTooltipWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  hideTooltip: PropTypes.bool,
  disablePortal: PropTypes.bool
};
