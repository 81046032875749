import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

export const ClearField = ({ handleChange, margin }) => {
  const theme = useTheme();

  const useStyles = makeStyles(() => ({
    clearFieldIcon: {
      color: theme.palette.gray.main,
      marginLeft: '1rem',
      cursor: 'pointer',
      border: '1px solid ' + theme.palette.gray.light,
      borderRadius: '50%',
      padding: '0.2rem',
      margin: margin,
      '&:hover': {
        backgroundColor: theme.palette.gray.light
      }
    }
  }));
  const classes = useStyles();

  return (
    <CloseIcon className={classes.clearFieldIcon} onClick={handleChange} data-testid='clearField' />
  );
};

ClearField.propTypes = {
  handleChange: PropTypes.func,
  margin: PropTypes.number
};
